
import { Component, Vue } from 'vue-property-decorator';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import FullScreenForm from '@/components/FullScreenForm.vue';
import { Location } from 'vue-router';
import { RouteNames } from '@/constants';
import { RpaOpsModule } from '@/store/om27.module';
import { LogicalBot, TicketType, UserPreferences } from '@/types/om27.types';
import { Promised } from 'vue-promised';
import TicketCard from '@/components/TicketCard.vue';

@Component({
  components: {
    Grid,
    GridCell,
    FullScreenForm,
    Promised,
    TicketCard,
  },
})
export default class OpenTicket extends Vue {
  closeRoute: Location = {
    name: RouteNames.Om27,
  };

  link(type: TicketType): Location {
    return {
      name: RouteNames.RpaOpsBlockingTicket,
      params: {
        logicalBotId: this.logicalBotId.toString(),
        botId: this.botId.toString(),
        type,
      },
    };
  }

  get timezone(): UserPreferences['preferred_timezone'] {
    return RpaOpsModule.userPreferences.preferred_timezone;
  }

  get bots(): LogicalBot[] {
    return RpaOpsModule.logicalBots;
  }

  get logicalBotId(): number {
    return parseInt(this.$route.params.logicalBotId, 10);
  }

  get botId(): number {
    return parseInt(this.$route.params.botId, 10);
  }

  get logicalBot(): LogicalBot | undefined {
    return this.bots.find(bot => bot.logicalBotId === this.logicalBotId);
  }

  get botSelectionIsValid(): boolean {
    return !!this.logicalBot?.subRecords.find(rec => rec.botId === this.botId);
  }

  goToDashboard(): void {
    this.$router
      .replace({
        name: RouteNames.Om27,
        query: this.$route.query || {
          expand: this.logicalBotId.toString(),
        },
      })
      .catch(() => void 0);
  }

  promise: Promise<unknown> | null = null;
  async created(): Promise<void> {
    if (!RpaOpsModule.logicalBots.length) {
      this.promise = RpaOpsModule.getDashboard();
    } else {
      this.promise = Promise.resolve();
    }

    await this.promise;
    if (!this.botSelectionIsValid) {
      this.goToDashboard();
    }
  }
}
